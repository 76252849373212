import { Injectable, EventEmitter } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LanguageService {

    langEmitter = new EventEmitter<string>();

    constructor() { }

    set(lang: string): void {
        localStorage.setItem('lang', lang);
        this.langEmitter.emit(lang);
    }

    get(): string {
        return localStorage.getItem('lang');
    }

    format(): string {
        return this.get().split('-')[0];
    }

}
