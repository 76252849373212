export const shuffle = (len) => {
  const str = '0123456789abcdefghjkmnpqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const arr = str.split('');
  const n = arr.length;

  for (let i = 0; i < n - 1; ++i) {
    const j = Math.floor(Math.random() * n);

    const temp = arr[i];
    arr[i] = arr[j];
    arr[j] = temp;
  }

  return arr.slice(0, len).join('');
};

export const isEmpty = (obj) => {
  const hasOwnProperty = Object.prototype.hasOwnProperty;

  if (obj == null) { return true; }
  if (obj.length > 0) { return false; }
  if (obj.length === 0) { return true; }
  if (typeof obj !== 'object') { return true; }
  for (const key in obj) {
      if (hasOwnProperty.call(obj, key)) { return false; }
    }

  return true;
};
