import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-chip',
    templateUrl: './chip.component.html',
    styleUrls: ['./chip.component.scss']
})
export class ChipComponent implements OnInit {

    @Input() data: any[];
    @Input() size = 1;
    @Input() selected: any[] = [];
    @Input() multiselect = false;
    @Output() itemChanged: EventEmitter<string[]> = new EventEmitter();

    constructor() {}

    ngOnInit(): void {}

    selectItem(item: any): void {
        if (this.multiselect) {
            if (this.selected.filter(e => e.id === item.id).length) {
                for (let index = 0; index < this.selected.length; index++) {
                    if (this.selected[index].id === item.id) {
                        this.selected.splice(index, 1);
                    }
                }
            } else {
                this.selected.push(item);
            }
            this.itemChanged.emit(this.selected);
        } else {
            this.itemChanged.emit([item]);
        }
    }

    ifSelected(item: any): boolean {
        if (this.multiselect) {
            for (const element of this.selected) {
                if (element.id === item.id) {
                    return true;
                }
            }
            return false;
        } else {
            return this.selected.includes(item) ? true : false;
        }
    }

}
