import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Routes } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from './services';

@Component({
    selector: 'app-root',
    template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {

    constructor(
        translate: TranslateService,
        private languageService: LanguageService,
        private route:ActivatedRoute,private router:Router
    ) {
        translate.addLangs(['en-EN', 'fr-FR', 'de-DE']);
        translate.use((languageService.get()) ? languageService.get() : 'en-EN');
    }

    ngOnInit(): void {
    }

}
