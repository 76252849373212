<div class="app">

    <app-navigation></app-navigation>

    <div class="content">

        <app-header></app-header>

        <div class="section">
            <router-outlet></router-outlet>
        </div>

    </div>
</div>