import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'playerTime' })
export class PlayerTimePipe implements PipeTransform {

    transform(value: number): string {
        const date = new Date(null);
        date.setSeconds(value);
        return date.toISOString().substr(11, 8);
    }

}
