import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { User } from 'src/app/models';

@Injectable({ providedIn: 'root' })
export class UserService {

    public currentUser: User;

    constructor(private http: HttpClient) { }

    get(): Observable<any> {
        return this.http.get(environment.api.me).pipe(map((res) => res));
    }

    set(user: User): void {
        this.currentUser = user;
    }

    autolog(id): Observable<any> {
        return this.http.get(environment.api.users + '/autolog/' + id ).pipe(map((res) => res));
    }

}
