<div class="video-player">

    <div class="card border-light">

        <div class="video-section" id="slopeEditorStep1">
            <video preload="auto" #videoPlayer (click)="toggleVideo()" (timeupdate)="timeUpdate($event)" [ngClass]="{'full': controls, 'reduce': !controls}">
                <source [src]="source">
                Browser not supported
            </video>
        </div>

        <div class="controls-section" *ngIf="controls">
            <div class="controls" id="slopeEditorStep7">
    
                <div class="time-zone" *ngIf="timeline">
                    <ngb-progressbar [value]="progressBarValue" max="100" height=".65rem" (click)="changeTime($event)">
                    </ngb-progressbar>
                </div>
    
                <div class="action-zone">
                    <button class="btn btn-link" (click)="toggleVideo()" placement="top" [ngbTooltip]="'VIDEO-PLAYER.play' | translate">
                        <i class="fas" [ngClass]="{'fa-play': !playBtn, 'fa-pause': playBtn}"></i>
                    </button>

                    <button class="btn btn-link speed-btn" placement="top" ngbTooltip="{{ 'VIDEO-PLAYER.speed' | translate }} x{{ currentSpeed }}" (click)="changeSpeed()">
                        <i class="fas fa-fast-forward"></i>
                        <span class="count">x{{ currentSpeed }}</span>
                    </button>
    
                    <div class="time">
                        <span>{{ currentTime | playerTime }}</span>
                        <span>/</span>
                        <span>{{ duration | playerTime }}</span>
                    </div>
                    
                    <!-- <button class="btn btn-link" (click)="changeUnit()" placement="top" [ngbTooltip]="(unitBySpeed) ? 'Display by speed' : 'Display by time'">
                        <i class="fas" [ngClass]="{'fa-clock': !unitBySpeed, 'fa-stopwatch-20': unitBySpeed}"></i>
                    </button> -->

                    <button class="btn btn-link" (click)="activateSync()" placement="top" [ngbTooltip]="'VIDEO-PLAYER.synchronize' | translate"  id="slopeEditorStep8">
                        <i class="fas fa-sync-alt" [ngClass]="{'text-primary': activatedSync}"></i>
                    </button>

    
                    <button class="btn btn-link" (click)="toggleMuted()" placement="top" [ngbTooltip]="'VIDEO-PLAYER.mute' | translate">
                        <i class="fas" [ngClass]="{'fa-volume-up': !mutedBtn, 'fa-volume-mute': mutedBtn}"></i>
                    </button>
    
                    <button class="btn btn-link" (click)="toggleFullscreen()" placement="left" [ngbTooltip]="'VIDEO-PLAYER.fullscreen' | translate">
                        <i class="fas fa-expand"></i>
                    </button>
    
                </div>
    
            </div>
        </div>

    </div>

</div>