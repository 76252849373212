<div class="header">

    <div class="title">
        <i class="fas fa-home mr-2" *ngIf="type === 'home'"></i>
        <i class="fas fa-search mr-2" *ngIf="type === 'edition'"></i>
        <i class="fas fa-chart-line mr-2" *ngIf="type === 'slopeEditor'" (click)="clickOnBox()"></i>
        <i class="fas fa-chart-line mr-2" *ngIf="type === 'workout'"></i>
        <i class="fas fa-search mr-2" *ngIf="type === 'workoutListing'"></i>
        <i class="fas fa-calendar mr-2" *ngIf="type === 'votw'"></i>
        {{ 'HEADER.' + type | translate }}
    </div>

    <ul class="nav-left" *ngIf="navsLeft.length">
        <li *ngFor="let nav of navsLeft">
            <div ngbDropdown class="d-inline-block" *ngIf="nav.type === 'dropdown'">
                <button class="btn btn-link shadow-none" ngbDropdownToggle>
                    <i class="icon" [ngClass]="nav.icon"></i>
                </button>
                <div ngbDropdownMenu>
                    <button ngbDropdownItem class="shadow-none" *ngFor="let item of nav.data">{{ item }}</button>
                </div>
            </div>
            <div class="px-3" *ngIf="nav.type === 'link'">
                {{ nav.name }}
            </div>
        </li>
    </ul>

    <!-- <form class="search-form">
        <div class="form-group m-0">
            <input type="text" class="form-control" name="search" placeholder="search">
            <i class="fas fa-search"></i>
        </div>
    </form> -->

    <ul class="nav-right" *ngIf="navsRight.length">
        <li *ngFor="let nav of navsRight">
            <!-- Dropdown items -->
            <div ngbDropdown class="d-inline-block" *ngIf="nav.type === 'dropdown'">
                <button class="btn btn-link shadow-none" ngbDropdownToggle>
                    <i class="icon" [ngClass]="nav.icon"></i>
                </button>
                <div ngbDropdownMenu>
                    <button ngbDropdownItem class="shadow-none" *ngFor="let item of nav.data">{{ item }}</button>
                </div>
            </div>
            <!-- Link items -->
            <div class="px-3" *ngIf="nav.type === 'link'">
                {{ nav.name }}
            </div>
            <!-- LinkIcon items -->
            <div class="px-3" *ngIf="nav.type === 'linkIcon' && type === 'workout'" id="lock" (click)="toggleItem(nav)">
                <i class="icon" [ngClass]="nav.icon"></i>
            </div>
            <!-- Toggle items -->
            <div ngbDropdown class="d-inline-block toggle-section" *ngIf="nav.type === 'toggle' && nav.data.length" id="settings">
                <button class="btn btn-link shadow-none" ngbDropdownToggle>
                    <i class="icon" [ngClass]="nav.icon"></i>
                </button>
                <div ngbDropdownMenu class="toggle-list p-0">
                    <div class="p-3 text-center d-flex justify-content-between">
                        <h6 class="mb-0">{{ 'HEADER.settings' | translate }}</h6>
                    </div>
                    <div class="d-flex" *ngFor="let item of nav.data">
                        <app-toggle [value]="item.value" (changeValue)="toggleItem(item, $event)"></app-toggle>
                        <span class="pl-2" (click)="toggleItem(item)">{{ item.label | translate }}</span>
                    </div>
                </div>
            </div>
        </li>
    </ul>

    <ul class="nav-lang">
        <li ngbDropdown display="dynamic" placement="bottom-right">
            <a class="nav-link py-1" ngbDropdownToggle role="button">
                <img src="./assets/images/flags/{{ currentLanguage }}.svg" height="22">
            </a>
            <div ngbDropdownMenu class="dropdown-language text-right">
                <a ngbDropdownItem *ngFor="let language of allAvailableLanguages" (click)="switchLang(language)">
                    <img src="./assets/images/flags/{{ language }}.svg" height="22">
                </a>
            </div>
        </li>
    </ul>

</div>