import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SwarmLoginService } from '../services';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss']
})
export class CallbackComponent implements OnInit {
  loading = true;
  constructor(public swarmLogin: SwarmLoginService, private route: ActivatedRoute) {
    if (this.route.snapshot.paramMap.get('provider')) {
      const provider = this.route.snapshot.paramMap.get('provider');

      let parameters;
      this.route.queryParams.subscribe((params) => (parameters = params));
      this.swarmLogin.getUserData(provider, parameters);
    }
  }

  ngOnInit(): void {
  }

}
