import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserService } from '../services';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EditionGuard implements CanActivate {

    constructor(
        private router: Router,
        private userService: UserService,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.userService.get().pipe(map(res => {
            if (res.data.is_admin) {
                return true;
            } else {
                this.router.navigate(['/home']);
                return false;
            }
        }));
    }
}
