import { Injectable } from '@angular/core';
import { EventService } from 'src/app/services';

@Injectable({ providedIn: 'root' })
export class AppConfig {

    CONFIG = {
        header: {
            left: [
                // { name: 'button 1', type: 'link' },
                // { name: 'button 2', type: 'link' }
            ],
            right: [
                {
                    name: 'draggable',
                    type: 'linkIcon',
                    icon: this.eventService.getLockItem() ? 'fas fa-lock-open' : 'fas fa-lock',
                    value: this.eventService.getLockItem()
                },
                // { name: 'Notification', type: 'dropdown', icon: 'far fa-bell', data: ['test 1', 'test 2'] },
                {
                    name: 'displaySettings',
                    type: 'toggle',
                    icon: 'fas fa-cog',
                    data: []
                }
            ]
        },
        navigation: {
            top: [
                // { name: 'Dashboard', icon: 'fas fa-tachometer-alt', route: ['/home'] },
                { name: 'NAVIGATION.edition', icon: 'fas fa-chart-bar', route: ['/edition'], onlyAdmin: false },
                { name: 'NAVIGATION.workout', icon: 'fas fa-child', route: ['/workout'], onlyAdmin: false },
                { name: 'NAVIGATION.votw', icon: 'fas fa-calendar', route: ['/video-of-the-week'], onlyAdmin: true },
            ],
            bottom: [
                // { name: 'Settings', icon: 'fas fa-cog', route: ['/settings'] },
                { name: 'NAVIGATION.logout', icon: 'fas fa-sign-out-alt', route: ['/login'] }
            ]
        },
        displaySettings: {
            home: [],
            edition: [],
            gpsonly: [],
            votw: [],
            slopeEditor: ['timeline', 'chart', 'map', 'table', 'options'],
            // We disable the instructions panel
            // workout: ['information', 'segments', 'chart', 'description', 'instructions'],
            workout: ['information', 'segments', 'chart', 'description'],
            workoutListing: []
        }
    };

    constructor(private eventService: EventService) { }

    get() { return this.CONFIG; }

}
