import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment, APP_TOKEN_CONVERT } from 'src/environments/environment';


interface SettingsData {
    interest?: string;
    tags?: string;
    quality?: string;
    approved?: '0' | '1' | '3';
    isRowingForward?: '0' | '1';
    is360?: true | false;
}

@Injectable({ providedIn: 'root' })
export class VideoService {

    constructor(private http: HttpClient) { }

    getAll(params?: any): Observable<any> {
        return this.http.get(environment.api.videos + 'search/elastic', { params }).pipe(map((res) => res));
    }

    getAllBySport(term?: string, sports?: string): Observable<any> {
        return this.http.get(environment.api.videos + 'search/elastic', { params: { term, sports, limit: 8 } }).pipe(map((res) => res));
    }

    getByID(id: string): Observable<any> {
        return this.http.get(environment.api.videos + id).pipe(map((res) => res));
    }

    getUrls(id: string): Observable<any> {
        return this.http.get(environment.api.videos + id + '/urls').pipe(map((res) => res));
    }

    getCoordinates(id: string): Observable<any> {
        return this.http.get(environment.api.videos + id + '/coordinates').pipe(map((res) => res));
    }

    getAllCoordinates(id: string): Observable<any> {
        return this.http.get(environment.api.videos + id + '/allCoordinates').pipe(map((res) => res));
    }

    getTrainingSections(id: string): Observable<any> {
        return this.http.get(environment.api.videos + id + '/training-sections').pipe(map((res) => res));
    }

    getTags(): Observable<any> {
        return this.http.get(environment.api.tags).pipe(map((res) => res));
    }

    updateTolerance(videoId: number, tolerance: number = 1): Observable<any> {
        return this.http.post(environment.api.videos + videoId + '/updateSlope', { tolerance }).pipe(map((res) => res));
    }

    updateTumbnail(hashId: string, time: number): Observable<any> {
        return this.http.patch(environment.api.thumbnail + hashId + "?appToken=" + APP_TOKEN_CONVERT, {"time": time}).pipe(map((res) => res)); 
    }

    updateSettings(id: string, data: SettingsData): Observable<any> {
        return this.http.post(environment.api.videos + id + '/updateSettings', data).pipe(map((res) => res));
    }

    updateTrainingSections(id: number, sections: any[]): Observable<any> {
        return this.http.post(environment.api.videos + id + '/updateTrainingSections', {
            sections: JSON.stringify(sections)
        }).pipe(map((res) => res));
    }

    editSync(id: number, startTime: number): Observable<any> {
        return this.http.post(environment.api.videos + id + '/editSync', { startTime }).pipe(map((res) => res));
    }

    canAccess(id): Observable<any> {
        return this.http.get(environment.api.videos + 'canAccess/' + id);
    }

}
