import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatVideoStats' })
export class FormatVideoStatsPipe implements PipeTransform {

    transform(value: number, args: string): number {
        if (args === 'kms') {
            return Math.round((value / 1000) * 10) / 10;
        } else if (args === 'mins') {
            return Math.round(value / 60);
        } else {
            return value;
        }
    }

}
