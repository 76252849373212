import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class DeviceGuard implements CanActivate {

    constructor(
        private router: Router,
    ) { }

    canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent) ){ 
            this.router.navigate(['/alert']);
            return false
        }else{
            return true;
        }
    }

    

}
