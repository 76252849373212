import { EventEmitter, Injectable } from '@angular/core';

interface Time {
  current: number;
  updateOnClick: boolean;
}

@Injectable({ providedIn: 'root' })
export class EventService {
  public autologged: EventEmitter<any> = new EventEmitter();
  public chartUnit: EventEmitter<string> = new EventEmitter();
  public ComponentChanged: EventEmitter<string> = new EventEmitter();
  public createWorkoutClicked: EventEmitter<any> = new EventEmitter();
  public deleteWorkoutClicked: EventEmitter<any> = new EventEmitter();
  public displayItem: EventEmitter<any> = new EventEmitter();
  public duplicateWorkoutClicked: EventEmitter<any> = new EventEmitter();
  public duration: EventEmitter<number> = new EventEmitter();
  public gridsterItemChanges: EventEmitter<string> = new EventEmitter();
  public isOnlyFollowerClicked: EventEmitter<any> = new EventEmitter();
  public isPrivateClicked: EventEmitter<any> = new EventEmitter();
  public isPublicClicked: EventEmitter<any> = new EventEmitter();
  public lockItem: EventEmitter<boolean> = new EventEmitter();
  public segmentsSelected: EventEmitter<any> = new EventEmitter();
  public segmentsUpdate: EventEmitter<any> = new EventEmitter();
  public sizeMap: EventEmitter<boolean> = new EventEmitter();
  public time: EventEmitter<Time> = new EventEmitter();
  public toogleRecommendedClicked: EventEmitter<any> = new EventEmitter();
  public updateWorkoutClicked: EventEmitter<any> = new EventEmitter();
  public videoSync: EventEmitter<boolean> = new EventEmitter();
  public workoutDataChanged: EventEmitter<any> = new EventEmitter();
  public zoomMap: EventEmitter<any> = new EventEmitter();

  constructor() {}

  generateLabelAndInterval(duration: number): any {
    let moduloLabel = 0; // afficher label toutes les X secondes
    let moduloInterval = 0; // afficher les intervales toutes les X secondes
    if (duration <= 15) {
      moduloLabel = 1;
      moduloInterval = 1;
    } else if (duration > 15 && duration <= 30) {
      moduloLabel = 2;
      moduloInterval = 1;
    } else if (duration > 30 && duration <= 60) {
      moduloLabel = 10;
      moduloInterval = 1;
    } else if (duration > 60 && duration < 300) {
      moduloLabel = 15;
      moduloInterval = 5;
    } else if (duration > 300 && duration < 600) {
      moduloLabel = 30;
      moduloInterval = 15;
    } else if (duration >= 600 && duration < 1800) {
      moduloLabel = 60;
      moduloInterval = 30;
    } else if (duration >= 1800 && duration < 3600) {
      moduloLabel = 300;
      moduloInterval = 60;
    } else if (duration >= 3600 && duration < 5400) {
      moduloLabel = 600;
      moduloInterval = 120;
    } else if (duration >= 5400 && duration < 7200) {
      moduloLabel = 900;
      moduloInterval = 300;
    } else if (duration >= 7200) {
      moduloLabel = 3600;
      moduloInterval = 600;
    }
    return { moduloInterval, moduloLabel };
  }

  getChartUnit(): boolean {
    if (localStorage.getItem('chart_unit')) {
      if (localStorage.getItem('chart_unit') === 'speed') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  getDisplayItem(type: string, name: string): boolean {
    if (localStorage.getItem('display_' + type + '_' + name)) {
      return JSON.parse(localStorage.getItem('display_' + type + '_' + name));
    } else {
      return true;
    }
  }

  getLockItem(): boolean {
    return localStorage.getItem('lock_draggable')
      ? JSON.parse(localStorage.getItem('lock_draggable'))
      : false;
  }

  getVolume(): boolean {
    return localStorage.getItem('muted')
      ? JSON.parse(localStorage.getItem('muted'))
      : false;
  }

  gridsterItemChanged(item: string): void {
    this.gridsterItemChanges.emit(item);
  }

  setDisplayItem(type: string, name: string, value: boolean): void {
    localStorage.setItem('display_' + type + '_' + name, JSON.stringify(value));
    this.displayItem.emit({ name, type, value });
  }

  setDuration(value: number): void {
    this.duration.emit(value);
  }

  setLockItem(value: boolean): any {
    localStorage.setItem('lock_draggable', JSON.stringify(value));
    this.lockItem.emit(value);
  }

  setTime(current: number, updateOnClick: boolean = false): void {
    this.time.emit({ current, updateOnClick });
  }

  setVolume(value: boolean): void {
    localStorage.setItem('muted', JSON.stringify(value));
  }

  updateChartUnit(speedUnit: boolean): void {
    const value = speedUnit ? 'speed' : 'time';
    localStorage.setItem('chart_unit', value);
    this.chartUnit.emit(value);
  }

  updateSizeMap(): void {
    this.sizeMap.emit(true);
  }

  updateZoomMap(position: any): void {
    this.zoomMap.emit(position);
  }
}
