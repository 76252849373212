import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { API_URL_V4, environment } from 'src/environments/environment';

import { Segment, Workout } from '../models';

@Injectable({
  providedIn: 'root',
})
export class WorkoutService {
  // To store filters params between road change
  params: any;

  constructor(private http: HttpClient) {}

  canAccess(id): Observable<any> {
    return this.http.get(environment.api.workout + '/canAccess/' + id);
  }

  createWorkout(workout: Workout): Observable<any> {
    // For each segment, duplicate the segment line for each value in values and set segment.value
    const duplicatedSegments: Segment[] = [];
    workout.segments.forEach((segment: Segment, segmentIndex: number) => {
      segment.values.forEach((value: number, valueIndex: number) => {
        const newSegment: Segment = {
          ...segment,
          color: segment.colors[valueIndex],
          name: segment.name
            ? `${segment.name}-${segmentIndex}`
            : `-${segmentIndex}`,
          value,
        };
        duplicatedSegments.push(newSegment);
      });
    });
    workout.segments = duplicatedSegments;

    return this.http
      .post(environment.api.workout, { data: JSON.stringify(workout) })
      .pipe(map((res) => res));
  }

  deleteWorkout(id: string): Observable<any> {
    return this.http
      .delete(environment.api.workout + '/deleteWorkout/' + id)
      .pipe(map((res) => res));
  }

  getAll(params): Observable<any> {
    return this.http
      .get(environment.api.workout, { params })
      .pipe(map((res) => res));
  }

  getMyWorkouts(params): Observable<any> {
    this.params = params;
    return this.http
      .get(environment.api.me + '/workout', { params })
      .pipe(map((res) => res));
  }

  getOne(id): Observable<any> {
    return this.http.get(environment.api.workout + '/' + id).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getParams() {
    return this.params;
  }

  getWorkoutThemes(): Observable<any> {
    return this.http
      .get(API_URL_V4 + '/workout/themes')
      .pipe(map((res: any) => res.data));
  }

  toogleRecommended(id): Observable<any> {
    return this.http
      .post(environment.api.workout + '/recommended/' + id, {})
      .pipe(map((res) => res));
  }

  updateWorkout(workout: Workout, id): Observable<any> {
    const duplicatedSegments: Segment[] = [];
    workout.segments.forEach((segment: Segment, segmentIndex: number) => {
      segment.values.forEach((value: number, valueIndex: number) => {
        const newSegment: Segment = {
          ...segment,
          color: segment.colors[valueIndex],
          name: segment.name
            ? `${segment.name}-${segmentIndex}`
            : `-${segmentIndex}`,
          value,
        };
        duplicatedSegments.push(newSegment);
      });
    });
    workout.segments = duplicatedSegments;

    return this.http
      .post(environment.api.workout + '/' + id, {
        data: JSON.stringify(workout),
      })
      .pipe(map((res) => res));
  }
}
