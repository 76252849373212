import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APP_TOKEN } from 'src/environments/environment';

@Injectable()
export class AppTokenInterceptor implements HttpInterceptor {

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.method != "PATCH" ) {
            request = request.clone({
                setParams: {
                    appToken: APP_TOKEN
                }
            });
        }
        return next.handle(request);
    }

}
