import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { EventService, UserService } from 'src/app/services';
import { User } from 'src/app/models';
import { AppConfig } from 'src/app/helpers';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NavigationComponent implements OnInit, OnDestroy {

    navsTop: any[];
    navsBottom: any[];
    currentUser: User;
    subscriptions: Subscription[] = [];

    constructor(private userService: UserService, private config: AppConfig, private eventService: EventService) { }

    ngOnInit(): void {
        this.navsTop = this.config.get().navigation.top;
        for (const [key, nav] of Object.entries(this.navsTop)) {
            if (nav.onlyAdmin) {
                this.navsTop.splice(+key, 1);
                this.userService.get().subscribe(res => {
                    if (res.data.is_admin) {
                        this.navsTop.push(nav);
                    }
                }, () => {
                    this.navsTop.splice(+key, 1);
                });
            }
        }
        this.navsBottom = this.config.get().navigation.bottom;
        if (localStorage.getItem('user_access_token')) {
            this.userService.get().subscribe(result => {
                this.currentUser = {
                    username: result.data.username,
                    avatar: result.data.avatar_url,
                    email: null,
                    roles: null
                };
            });
        }
        this.subscriptions.push(this.eventService.autologged.subscribe(() => {
            this.userService.get().subscribe(result => {
                this.currentUser = {
                    username: result.data.username,
                    avatar: result.data.avatar_url,
                    email: null,
                    roles: null
                };
            });
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

}
