import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class AuthService {

    constructor(private http: HttpClient) { }

    setUserToken(token: string): void {
        localStorage.setItem('user_access_token', token);
    }

    getUserToken(): string {
        return localStorage.getItem('user_access_token');
    }

    login(user: string, password: string | Int32Array): Observable<any> {
        return this.http.post(environment.api.auth, { login: user, md5Pwd: password }).pipe(map((res) => res));
    }

    logout(): void {
        localStorage.removeItem('user_access_token');
    }


}
