import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
    selector: 'app-toggle',
    templateUrl: './toggle.component.html',
    styleUrls: ['./toggle.component.scss']
})
export class ToggleComponent{

    @Input() value: boolean;
    @Output() changeValue: EventEmitter<boolean> = new EventEmitter();

    constructor() { }


    switched(value: boolean): void {
        this.changeValue.emit(value);
        
    }



    

}
