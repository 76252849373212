import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventService, UserService } from '../services';

@Component({
  selector: 'app-autolog',
  templateUrl: './autolog.component.html',
  styleUrls: ['./autolog.component.scss']
})
export class AutologComponent implements OnInit {

  loading = true;

  constructor(
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private eventService: EventService
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      // check if already logged
      if (!localStorage.getItem('user_access_token')) {
        this.userService.autolog(params.id).subscribe(res => {
          if (res.uat === false) {
            this.router.navigate(['/login'], { queryParams: { returnUrl: '/' + params.url } });
          } else {
            localStorage.setItem('user_access_token', res.uat);
            this.eventService.autologged.emit();
            this.router.navigate(['/' + params.url]);
          }
        }, () => {
          this.router.navigate(['/login'], { queryParams: { returnUrl: '/' + params.url } });
        });
      } else {
        this.router.navigate(['/' + params.url]);
      }
    });
  }

}
