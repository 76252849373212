import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AlertComponent } from './alert/alert.component';
import { AutologComponent } from './autolog/autolog.component';
import { CallbackComponent } from './callback/callback.component';
import { AuthGuard, EditionGuard } from './guards';
import { DeviceGuard } from './guards/device.guard'
import { PageComponent } from './page.component';

const routes: Routes = [
    { path: '', redirectTo: '/home', pathMatch: 'full' },
    {
        path: 'login',
        loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule)
    },
    {
        path: 'autolog',
        component: AutologComponent
    },
    {
        path: 'alert',
        component: AlertComponent,
    },
    {
        path: 'callback/:provider',
        component: CallbackComponent,
    },
    {
        path: '',
        component: PageComponent,
        children: [
            {
                path: 'home',
                loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
                canActivate : [DeviceGuard]
            },
            {
                path: 'edition',
                loadChildren: () => import('./modules/edition/edition.module').then(m => m.EditionModule),
                canActivate: [AuthGuard, DeviceGuard]
            },
            {
                path: 'video-of-the-week',
                loadChildren: () => import('./modules/votw/votw.module').then(m => m.VotwModule),
                canActivate: [AuthGuard, EditionGuard, DeviceGuard],
            },
            {
                path: 'workout',
                loadChildren: () => import('./modules/workout/workout.module').then(m => m.WorkoutModule),
                canActivate: [AuthGuard, DeviceGuard]
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
