<div class="main">
    <div class="alert">
        <div class="img">
            <img src="https://dashboard.kinomap.com/images/kinomap_logo.png" alt="">
        </div>
        <div class="msg">
            <p class="text-center">{{ 'ALERT.text' | translate }}</p>
        </div>
    </div>
</div>


        
    
