import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService, EventService } from 'src/app/services';
import { Subscription } from 'rxjs';
import { AppConfig } from 'src/app/helpers';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

    type: string;
    navsLeft: any[];
    navsRight: any[];
    allAvailableLanguages: any[];
    currentLanguage: any;
    subscription: Subscription;

    constructor(
        public translate: TranslateService,
        private languageService: LanguageService,
        private eventService: EventService,
        private config: AppConfig
    ) {
        this.currentLanguage = (languageService.get()) ? languageService.get() : translate.defaultLang;
        this.allAvailableLanguages = translate.getLangs().filter(language => {
            if (language !== this.currentLanguage) {
                return language;
            }
        });
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    ngOnInit(): void {
        this.subscription = this.eventService.ComponentChanged.subscribe(displaySettingsName => {
            this.type = displaySettingsName;
            for (const navRight of this.navsRight) {
                if (navRight.name === 'displaySettings') {
                    if (displaySettingsName) {
                        navRight.data = [];
                        for (const setting of this.config.get().displaySettings[this.type]) {
                            if (setting !== 'information') {
                                navRight.data.push({
                                    name: setting,
                                    label: 'HEADER.show.' + setting,
                                    value: this.eventService.getDisplayItem(this.type, setting)
                                });
                            }
                        }
                    } else {
                        navRight.data = [];
                    }
                }

            }
        });
        this.navsLeft = this.config.get().header.left;
        this.navsRight = this.config.get().header.right;

    }


    switchLang(lang: string) {
        this.translate.use(lang);
        this.currentLanguage = lang;
        this.languageService.set(lang);
        this.allAvailableLanguages = this.translate.getLangs().filter(language => {
            if (language !== this.currentLanguage) {
                return language;
            }
        });
    }

    toggleItem(item: any, event?: any): void {
        item.value = (event) ? event : !item.value;
        this.eventService.setDisplayItem(this.type, item.name, item.value);
        if (item.name === 'draggable') {
            this.eventService.setLockItem(item.value);
            item.icon = !item.value ? 'fas fa-lock' : 'fas fa-lock-open';
        }
    }

    clickOnBox(): void {
        localStorage.setItem('ee', 'ee');
    }



}
