import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService, VideoService } from '../services';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class VideoGuard implements CanActivate {

    constructor(
        private router: Router,
        private authService: AuthService,
        private videoService: VideoService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        if (this.authService.getUserToken()) {
            return this.videoService.canAccess(route.paramMap.get('id')).pipe(map(res => {
                if (res.canAccess) {
                    return true;
                } else {
                    this.router.navigate(['/edition']);
                    return false;
                }
            }));
        }
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/edition']);
        return of(false);
    }
}
