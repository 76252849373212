import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Segment, Workout } from 'app/models';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { API_URL_V4, environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WorkoutService {
  // To store filters params between road change
  params: HttpParams = new HttpParams();

  constructor(private http: HttpClient) {}

  canAccess(id): Observable<any> {
    return this.http.get(environment.api.workout + '/canAccess/' + id);
  }

  createWorkout(workout: Workout): Observable<any> {
    workout = this.mapWorkout(workout);
    return this.http
      .post(environment.api.workout, { data: JSON.stringify(workout) })
      .pipe(map((res) => res));
  }

  deleteWorkout(id: string): Observable<any> {
    return this.http
      .delete(environment.api.workout + '/' + id)
      .pipe(map((res) => res));
  }

  getAll(params): Observable<any> {
    return this.http
      .get(environment.api.workout, { params })
      .pipe(map((res) => res));
  }

  getMyWorkouts(params): Observable<any> {
    Object.entries(params).forEach(([key, value]) => {
      this.params = this.params.set(key, value as string);
    });

    this.params = this.params.delete('modes[]');

    if (params.modes) {
      params.modes.split('|').forEach((mode: string) => {
        this.params = this.params.append('modes[]', mode);
      });
    }

    return this.http
      .get(environment.api.me + '/workouts', { params: this.params })
      .pipe(map((res) => res));
  }

  getOne(id): Observable<any> {
    return this.http.get(environment.api.workout + '/' + id).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getParams(): HttpParams {
    return this.params;
  }

  getWorkoutThemes(): Observable<any> {
    return this.http
      .get(API_URL_V4 + '/workout/themes')
      .pipe(map((res: any) => res.data));
  }

  mapWorkout(workout: Workout): Workout {
    const segments: Segment[] = [];

    workout.segments.forEach((segment: Segment) => {
      segments.push({ ...segment, color: segment.colors[0] });
      if (segment.value_secondary) {
        segments.push({
          ...segment,
          color: segment.colors[0],
          value: segment.value_secondary,
        });
      }
    });

    return { ...workout, segments };
  }

  toogleRecommended(id): Observable<any> {
    return this.http
      .post(environment.api.workout + '/recommended/' + id, {})
      .pipe(map((res) => res));
  }

  updateWorkout(workout: Workout, id): Observable<any> {
    workout = this.mapWorkout(workout);
    return this.http
      .patch(environment.api.workout + '/' + id, {
        data: JSON.stringify(workout),
      })
      .pipe(map((res) => res));
  }
}
