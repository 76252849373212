import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class GpsService {
  constructor(private http: HttpClient) { }

  getByID(id: string): Observable<any> {
    return this.http.get(environment.api.gps + id).pipe(map((res) => res));
  }

  getTrainingSections(id: string): Observable<any> {
    return this.http
      .get(environment.api.gps + id + '/training-sections')
      .pipe(map((res) => res));
  }

  getCoordinates(id: string): Observable<any> {
    return this.http
      .get(environment.api.gps + id + '/coordinates')
      .pipe(map((res) => res));
  }

  updateTolerance(id: number, tolerance: number = 1): Observable<any> {
    return this.http
      .post(environment.api.gps + id + '/updateSlope', { tolerance })
      .pipe(map((res) => res));
  }

  updateTrainingSections(id: number, sections: any[]): Observable<any> {
    return this.http
      .post(environment.api.gps + id + '/updateTrainingSections', {
        sections: JSON.stringify(sections),
      })
      .pipe(map((res) => res));
  }

  canAccess(id): Observable<any> {
    return this.http.get(environment.api.gps + 'canAccess/' + id);
  }

  updateStatus(status: number, id: number): Observable<any> {
    return this.http.post(environment.api.gps + 'updateStatus/' + id , { status }).pipe(map((res) => res));
  }
}
