import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
    ChipComponent, HeaderComponent, LeafletComponent, LoaderComponent, NavigationComponent,
    VideoPlayerComponent, PlayerTimePipe, ToggleComponent, VideoCardComponent
} from './components/_index';
import { FormatTimeSecondToMinutePipe, FormatVideoStatsPipe } from 'src/app/pipes';

const COMPONENTS = [
    ChipComponent,
    HeaderComponent,
    LeafletComponent,
    LoaderComponent,
    NavigationComponent,
    VideoPlayerComponent,
    PlayerTimePipe,
    ToggleComponent,
    FormatVideoStatsPipe,
    FormatTimeSecondToMinutePipe,
    VideoCardComponent
];

@NgModule({
    declarations: [...COMPONENTS, VideoCardComponent],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        TranslateModule,
        NgbModule
    ],
    exports: [...COMPONENTS],
    providers: []
})
export class SharedModule { }
