import { Injectable, Inject, EventEmitter, Output, NgZone } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Buffer } from 'buffer';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { AuthService, UserService } from '.';
import { shuffle, isEmpty } from 'src/app/helpers/index';

declare const AppleID: any;
declare const gapi: any;
declare const google: any;

interface LoaderStatus {
  type: string;
  value?: string;
}

@Injectable({
  providedIn: 'root',
})
export class SwarmLoginService {
  loadingStat = new Subject<LoaderStatus>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private zone: NgZone,
    private http: HttpClient,
    private router: Router,
    private authService: AuthService,
    private userService: UserService
  ) {}

  login(provider: string) {
    switch (provider) {
      case 'strava':
        this.document.location.href = `${
          environment.strava.loginUrl
        }?client_id=${
          environment.strava.client_id
        }&response_type=code&scope=read_all,profile:read_all,activity:read_all,activity:write&redirect_uri=${
          environment.strava.redirectUrl}`;
        break;

      case 'decathlon':
        this.document.location.href = `${
          environment.decathlon.loginUrl
        }?client_id=${
          environment.decathlon.config.client_id
        }&response_type=code&redirect_uri=${
          environment.decathlon.redirectUrl
        }&state=${shuffle(
          15
        )}&scope=profile openid email sports_tracking_data`;
        break;

      case 'google':
        this.signInGoogle();
        break;

      case 'google2':
        this.signInGoogleNew();
        break;

      case 'apple':
        AppleID.auth.init({
          clientId : 'com.kinomap',
          scope : 'name email',
          redirectURI : environment.apple.redirect_url,
          usePopup : true
        });
        this.signInAppleReturn();
        break;

      default:
        this.router.navigate(['/login']);
        break;
    }
  }

  signInGoogleNew() {
    google.accounts.id.initialize({
      client_id: environment.google.client_id,
      mode_ux: "popup",
      cancel_on_tap_outside: false,
      callback: (response: any) => {
        const responsePayload = this.decodeJwtResponse(response.credential);

        const params = {
          id: responsePayload.sub,
          email: responsePayload.email,
          name: responsePayload.name,
          firstname: responsePayload.given_name,
          lastname: responsePayload.family_name,
          picture: responsePayload.picture,
        };

        this.http
        .post(environment.api.swarm.google, {
          google_data: JSON.stringify(params),
        })
        .subscribe({
          next: r => this.zone.run(() => {this.validateLogin(r); }),
          error: () => this.sendStatus('error', 'Api is not accessible')
        });
      }
    });
    google.accounts.id.prompt((notification) => {
      if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
          document.cookie =  `g_state=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT`;
          google.accounts.id.prompt()
      }
    });

  }

  decodeJwtResponse(token) {
    const _decodeToken = (token) => {
      try {
        return JSON.parse(atob(token));
      } catch {
        return;
      }
    };
    return token
      .split('.')
      .map(token => _decodeToken(token))
      .reduce((acc, curr) => {
        if (!!curr) acc = { ...acc, ...curr };
        return acc;
      }, Object.create(null));
  }

  signInGoogle() {
    try {
      gapi.load('auth2', () => {
        const auth2 = gapi.auth2.init({
          scope: 'email',
          client_id: environment.google.client_id,
        });

        const resp: Promise<any> = auth2.signIn();
        resp.then((data) => {
          const userProfil = data.getBasicProfile();
          const accessToken = data.getAuthResponse().access_token;

          const params = {
            id: userProfil.getId(),
            email: userProfil.getEmail(),
            name: userProfil.getName(),
            firstname: userProfil.getGivenName(),
            lastname: userProfil.getFamilyName(),
            picture: userProfil.getImageUrl(),
            refresh_token: accessToken,
          };

          this.http
            .post(environment.api.swarm.google, {
              google_data: JSON.stringify(params),
            })
            .subscribe({
              next: r => this.zone.run(() => {this.validateLogin(r); }),
              error: () => this.sendStatus('error', 'Api is not accessible')
            });
        });
      });
    } catch (err) {
      this.sendStatus('error', 'Google-Api is not accessible');
    }
  }

  signInAppleReturn() {
    try {
      const resp: Promise<any> = AppleID.auth.signIn();

      resp.then((data) => {
        const authorization = data.authorization;
        const token = authorization.id_token.split('.');
        const decoded = Buffer.from(token[1], 'base64').toString('binary');

        const userData = JSON.parse(decoded);

        const params = {
          id: userData.sub,
          email: userData.email,
          firstname: null,
          lastname: null
        };

        if (data.user) {
          params.firstname = data.user.name.firstName;
          params.lastname = data.user.name.lastName;
        }

        this.http.post(environment.api.swarm.apple, {apple_data: JSON.stringify(params)})
        .subscribe( {
          next: r => this.validateLogin(r),
          error: () => this.sendStatus('error', 'Api is not accessible')
        });

      });
    } catch (error) {
      this.sendStatus('error', 'Apple-Api is not accessible');
    }
  }

  // CallBack URL
  getUserData(provider: string, data: any) {
    let params: any;

    if (!isEmpty(data)) {
      switch (provider) {
        // Strava CallBack
        case 'strava':
          params = {
            client_id: environment.strava.client_id,
            client_secret: environment.strava.client_secret,
            code: data.code
          };

          this.http
            .post(environment.strava.tokenUrl, params)
            .subscribe({
              next: res => {
                this.http
                  .post(environment.api.swarm.strava, {
                    strava_data: JSON.stringify(res),
                  })
                  .subscribe({
                    next: Apiresp => this.validateLogin(Apiresp),
                    error: () => this.sendStatus('error', 'Api is not accessible')
                  });
              },
              error: () => this.sendStatus('error', 'Strava-Api is not accessible')
            });
          break;

        // Decathlon Callback
        case 'decathlon':

          this.http.get(
              `${environment.decathlon.dataUrl}?client_id=${environment.decathlon.config.client_id}&grant_type=authorization_code&code=${data.code}&client_secret=${environment.decathlon.config.client_secret}&redirect_uri=${environment.decathlon.redirectUrl}`
            )
            .subscribe((res: any) => {
              const tokenData = {
                token : res.access_token,
                ref_token: res.refresh_token
              };

              this.http.post(environment.api.swarm.decathlon, {
                decathlon_data: JSON.stringify(tokenData)
              })
              .subscribe({
                next: r => this.validateLogin(r),
                error: e => this.sendStatus('error', 'Decathlon-Api is not accessible')
              });
            });
          break;

        default:
          this.router.navigate(['/login']);
          break;
      }
    } else {
      this.router.navigate(['/login']);
    }
  }

  validateLogin(response) {
    const { data } = response;

    if (response && data && data.userAccessToken) {
      this.authService.setUserToken(data.userAccessToken);
      const currentUser = {
        username: data.userName,
        avatar: data.userAvatars,
        email: data.userEmail,
        roles: data.roles,
      };
      this.userService.set(currentUser);

      this.router.navigate(['/home']);

    }
  }

  sendStatus(type: string, value?: string) {
    this.zone.run(() => {
      this.loadingStat.next({type, value});
    });
  }
}
