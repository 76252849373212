import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { API_URL_V4, environment } from 'src/environments/environment';
import { Workout } from '../models';

@Injectable({
  providedIn: 'root'
})
export class WorkoutService {
  // To store filters params between road change
  params: any;

  constructor(private http: HttpClient) { }

  createWorkout(data: Workout): Observable<any> {
    return this.http.post(environment.api.workout , { data: JSON.stringify(data) }).pipe(map((res) => res));
  }

  updateWorkout(data: Workout, id): Observable<any> {
    return this.http.post(environment.api.workout + '/' + id , { data: JSON.stringify(data) }).pipe(map((res) => res));
  }

  deleteWorkout(id): Observable<any> {
    return this.http.delete(environment.api.workout + '/deleteWorkout/' + id ).pipe(map((res) => res));
  }

  getAll(params): Observable<any> {
    return this.http.get(environment.api.workout, {params}).pipe(map((res) => res));
  }

  getOne(id): Observable<any> {
    return this.http.get(environment.api.workout + '/' + id).pipe(map((res) => res));
  }

  getMyWorkouts(params): Observable<any> {
    this.params = params;
    return this.http.get(environment.api.me + '/workout', {params}).pipe(map((res) => res));
  }

  canAccess(id): Observable<any> {
    return this.http.get(environment.api.workout + '/canAccess/' + id);
  }

  toogleRecommended(id): Observable<any> {
    return this.http.post(environment.api.workout + '/recommended/' + id, {}).pipe(map((res) => res));
  }

  getWorkoutThemes(): Observable<any> {
    return this.http.get(API_URL_V4 + '/workout/themes').pipe(map((res: any) => res.data));
  }

  getParams() {
    return this.params;
  }
}
