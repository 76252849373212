<div class="card-body p-2">
    <div class="sport">
        <img src="./assets/images/{{video.data.sport}}.svg" [alt]="video.data.sport" height="22">
    </div>
    <div class="slope-status">
        <i class="fas fa-user-edit" *ngIf="video.data.slope_edited === 0" [ngbTooltip]="'EDITION.EDITION.undefined' | translate" placement="bottom"></i>
        <i class="fas fa-check" *ngIf="video.data.slope_edited === 1" [ngbTooltip]="'EDITION.EDITION.approuved' | translate" placement="bottom"></i>
        <i class="fas fa-times" *ngIf="video.data.slope_edited === 2" [ngbTooltip]="'EDITION.EDITION.refused' | translate" placement="bottom"></i>
        <i class="fas fa-user-check" *ngIf="video.data.slope_edited === 3" [ngbTooltip]="'EDITION.EDITION.slopeProcessed' | translate" placement="bottom"></i>
    </div>
    <div class="video-thumbnails">
        <img class="video-img" [src]="video.data.thumbnails?.medium">
        <div class="view-count">
            <i class="fas fa-eye"></i>
            {{ video.data.views_count }}
        </div>
    </div>
    <div class="title">
        <span>{{ video.data.name }}</span>
    </div>
    <div class="stats">
        <div class="stat">
            <span class="value">{{ video.data.distance | formatVideoStats: 'kms' }}</span>
            <span class="unity">kms</span>
            <i class="fas fa-arrows-alt-h"></i>
        </div>
        <div class="stat">
            <span class="value">{{ video.data.duration | formatVideoStats: 'mins' }}</span>
            <span class="unity">mins</span>
            <i class="fas fa-clock"></i>
        </div>
        <div class="stat">
            <span class="value">{{ video.data.elevation_ascent }}</span>
            <span class="unity">m</span>
            <i class="fas fa-mountain"></i>
        </div>
        <div class="stat">
            <span class="value">{{ video.data.avg_speed }}</span>
            <span class="unity">km/h</span>
            <i class="fas fa-tachometer-alt"></i>
        </div>
    </div>
    <div class="author">
        <img [src]="video.data.author.avatar_url" height="28" [alt]="video.data.author.username">
        <span>{{ video.data.author.username }}</span>
        <div class="country-flag">
            <img src="./assets/images/newFlags/{{video.data.country_start.code }}.png"  [alt]="video.data.country_start.name" height="25">
        </div>
    </div>
</div>