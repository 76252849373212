import { Component, AfterViewInit, OnInit, Input, HostListener, ViewEncapsulation, OnDestroy } from '@angular/core';
import { EventService, VideoService } from 'src/app/services';
import { forkJoin, Subscription } from 'rxjs';
import * as L from 'leaflet';
import 'leaflet-geometryutil';
import * as polyUtil from 'polyline-encoded';
import * as Moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-leaflet',
    templateUrl: './leaflet.component.html',
    styleUrls: ['./leaflet.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LeafletComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() videoData: any;
    @Input() coordinates: any;
    loading: boolean;
    map: any;
    displayedPolyline: any;
    polyline: any[];
    draggableMarker: any;
    layerGroupVideo: any;
    videoSync: boolean;
    currentVideoTime: any;
    allCoordinates: any[];
    subscriptions: Subscription[] = [];

    @HostListener('document:click', ['$event']) clickout(event) {
        if (event.target.classList.contains('markerPopupBtn')) {
            this.map.closePopup();
            this.loading = true;
            const closetPosition = +event.target.getAttribute('data-type');
            const timeToSync = (this.currentVideoTime) ? this.currentVideoTime.toFixed(3) * 1000 : 0;
            const startTime = Moment.utc(closetPosition).valueOf() - timeToSync;
            this.videoService.editSync(this.videoData.id, startTime).subscribe(result => {
                this.loading = false;
            });
        }
    }

    constructor(
        private eventService: EventService,
        private videoService: VideoService,
        private translate: TranslateService
    ) { }

    ngOnInit(): void {
        this.videoService.getAllCoordinates(this.videoData.id).subscribe(coordinates => {
            this.allCoordinates = coordinates.data;
        });
        this.subscriptions.push(this.eventService.sizeMap.subscribe(update => {
            if (update) {
                setTimeout(() => this.map.invalidateSize());
            }
        }));
        this.subscriptions.push(this.eventService.time.subscribe(time => {
            this.currentVideoTime = time.current;
            if (this.videoSync) {
                return;
            }
            const position = this.coordinates.find(element => {
                if (Math.round(element.position) === Math.round(time.current)) {
                    return element;
                }
            });
            if (position) {
                const latLngPosition = new L.LatLng(position.latitude, position.longitude);
                const closestPolylinePointLatLng = L.GeometryUtil.closest(this.map, this.displayedPolyline, latLngPosition, true);
                this.updateElementsFromLatLng(closestPolylinePointLatLng, false);
            }
        }));
        this.subscriptions.push(this.eventService.zoomMap.subscribe(position => {
            this.map.flyTo([position.lat, position.lng], position.zoom);
        }));
        this.subscriptions.push(this.eventService.videoSync.subscribe((sync: boolean) => {
            this.videoSync = sync;
            this.layerGroupVideo.clearLayers();
            this.loading = true;
            if (sync) {
                this.syncLayer();
            } else {
                forkJoin([
                    this.videoService.getByID(this.videoData.id),
                    this.videoService.getCoordinates(this.videoData.id)
                ]).subscribe(([details, coordinates]) => {
                    this.originalLayer(details.data, coordinates.data);
                    this.videoData = details.data;
                    this.coordinates = coordinates.data;
                });
            }
        }));
    }

    ngAfterViewInit(): void {
        this.createMap();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    private createMap(): void {
        this.map = L.map('map', {});
        const osmLayer = L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            minZoom: 10,
            maxZoom: 20
        });
        const googleStreets = L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
            minZoom: 10,
            maxZoom: 20,
            subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
        });
        const googleSat = L.tileLayer('http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
            minZoom: 10,
            maxZoom: 20,
            subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
        });
        const googleTerrain = L.tileLayer('http://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}', {
            minZoom: 10,
            maxZoom: 20,
            subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
        });
        const baseLayers = {
            'Google Streets': googleStreets,
            'Google Satellite': googleSat,
            'Google Terrain': googleTerrain,
            OSM: osmLayer
        };
        const overlays = {};
        L.control.layers(baseLayers, null, {}).addTo(this.map);

        this.map.addLayer(googleStreets);
        this.originalLayer(this.videoData, this.coordinates);
    }

    private originalLayer(video: any, coordinates: any[]): void {
        const ee = (localStorage.getItem('ee') === 'egg') ? true : false;
        if (ee) { localStorage.removeItem('ee'); }
        // Creation of a layerGroup to put all the elements of the map in it
        this.layerGroupVideo = L.layerGroup().addTo(this.map);
        this.preparePolyline(this.map, 'red', video.polyline);
        // Onclick method on polyline
        this.displayedPolyline.on('click', (e) => {
            const closestPolylinePointLatLng = L.GeometryUtil.closest(this.map, this.displayedPolyline, e.latlng, true);
            if (closestPolylinePointLatLng !== null) {
                this.updateElementsFromLatLng(closestPolylinePointLatLng);
            }
        });
        // Add starting marker
        this.addMarker(coordinates[0].latitude, coordinates[0].longitude, {
            iconUrl: !ee ? 'assets/images/icons/flag-start.svg' : 'assets/images/icons/ee-start.svg',
            iconSize: [24, 24],
            iconAnchor: [12, 24]
        });
        // Add end marker
        this.addMarker(coordinates[coordinates.length - 1].latitude, coordinates[coordinates.length - 1].longitude, {
            iconUrl: !ee ? 'assets/images/icons/flag-end.svg' : 'assets/images/icons/ee-end.svg',
            iconSize: [24, 24],
            iconAnchor: [5, 24]
        });
        // Creation of bike icon
        const iconBike = new L.Icon({
            className: !ee ? 'bike-icon' : '',
            iconUrl: !ee ? 'assets/images/icons/bike.svg' : 'assets/images/icons/ee-bike.svg',
            iconSize: [28, 28],
            iconAnchor: [14, 14]
        });
        const position = new L.LatLng(coordinates[0].latitude, coordinates[0].longitude);
        this.draggableMarker = new L.Marker(position, { draggable: true, icon: iconBike, zIndexOffset: 1000 });
        this.layerGroupVideo.addLayer(this.draggableMarker);
        // Drag&Drop method bike icon
        this.draggableMarker.on('dragend', (e) => {
            // Position where the marker was dropped
            const markerPositionLatLng = e.target.getLatLng();
            // Nearest polyline point
            let closestPolylinePointLatLng = L.GeometryUtil.closest(this.map, this.displayedPolyline, markerPositionLatLng, true);
            if (closestPolylinePointLatLng === null) {
                // A problem occurred during the distance calculation, we reset everything to 0
                closestPolylinePointLatLng = new L.LatLng(this.coordinates[0].latitude, this.coordinates[0].longitude);
            }
            // We find the second which corresponds to the position to update the reader and the graph
            this.updateElementsFromLatLng(closestPolylinePointLatLng);
        });
        if (this.loading) { this.loading = false; }
    }

    private syncLayer(): void {
        // Creation of a layerGroup to put all the elements of the map in it
        this.layerGroupVideo = L.layerGroup().addTo(this.map);
        this.preparePolyline(this.map, 'green', this.encodePolyline(this.allCoordinates));
        // Creation of bike icon
        const syncIcon = new L.Icon({
            iconUrl: 'assets/images/icons/pin-sync.svg',
            iconSize: [32, 64],
            iconAnchor: [16, 50]
        });
        const position = new L.LatLng(this.coordinates[0].latitude, this.coordinates[0].longitude);
        this.draggableMarker = new L.Marker(position, { draggable: true, icon: syncIcon, zIndexOffset: 1000 });
        this.layerGroupVideo.addLayer(this.draggableMarker);
        // Drag&Drop method pin icon
        this.draggableMarker.on('dragend', (e) => {
            // Position where the marker was dropped
            const markerPositionLatLng = e.target.getLatLng();
            // // Nearest polyline point
            let closestPolylinePointLatLng = L.GeometryUtil.closest(this.map, this.displayedPolyline, markerPositionLatLng, true);
            if (closestPolylinePointLatLng === null) {
                // A problem occurred during the distance calculation, we reset everything to 0
                closestPolylinePointLatLng = new L.LatLng(this.coordinates[0].latitude, this.coordinates[0].longitude);
            }
            // We find the second which corresponds to the position to update the reader and the graph
            this.updateElementsFromLatLng(closestPolylinePointLatLng, false);
            // Determinate startTime and hashID
            const closetPosition = this.closestLocation(markerPositionLatLng, this.allCoordinates);
            // Open the popup marker
            this.createMarkerPopup(closetPosition);
        });
        this.loading = false;
    }

    private preparePolyline(map: any, color: string, polyline: any): void {
        if (polyline) {
            this.polyline = this.decodePolyline(polyline);
            if (this.polyline.length) {
                this.displayedPolyline = new L.Polyline(this.polyline, {
                    color,
                    weight: 5,
                    opacity: 0.7,
                    smoothFactor: 1
                });
                this.layerGroupVideo.addLayer(this.displayedPolyline);
                map.fitBounds(this.displayedPolyline.getBounds());
            } else {
                console.log('error decoding/encoding polyline', polyline);
            }
        }
    }

    private encodePolyline(coordinates: any[]): any {
        const latlngs = [];
        for (const coordinate of coordinates) {
            latlngs.push(new L.LatLng(coordinate.latitude, coordinate.longitude));
        }
        return polyUtil.encode(latlngs);
    }

    private decodePolyline(polyline: string): any[] {
        return polyUtil.decode(polyline);
    }

    private addMarker(lat: number, lon: number, icon: any): void {
        const iconMarker = new L.Icon(icon);
        const position = new L.LatLng(lat, lon);
        const marker = new L.Marker(position, { icon: iconMarker });
        this.layerGroupVideo.addLayer(marker, { icon: iconMarker });
    }

    private updateElementsFromLatLng(latlng, updateTime = true): void {
        const result = this.displayedPolyline.getLatLngs().find(element => {
            if (latlng.lat === element.lat && latlng.lng === element.lng) {
                return element;
            }
        });
        if (result) {
            const newPositionLatLng = new L.LatLng(result.lat, result.lng);
            if (!this.draggableMarker.getLatLng().equals(newPositionLatLng)) {
                this.draggableMarker.setLatLng(newPositionLatLng);
                if (updateTime) {
                    const closest = this.closestLocation(latlng, this.coordinates);
                    this.eventService.setTime(closest.position, true);
                }
                // If marker is outside the map, we refocus the map on it
                if (!this.map.getBounds().contains(newPositionLatLng)) {
                    this.map.panTo(newPositionLatLng);
                }
            }
        }
    }

    private closestLocation(targetLocation, locationData): any {
        function vectorDistance(dx, dy) {
            return Math.sqrt(dx * dx + dy * dy);
        }

        function locationDistance(location1, location2) {
            const dx = location1.lat - location2.latitude;
            const dy = location1.lng - location2.longitude;
            return vectorDistance(dx, dy);
        }

        return locationData.reduce((prev, curr) => {
            const prevDistance = locationDistance(targetLocation, prev);
            const currDistance = locationDistance(targetLocation, curr);
            return (prevDistance < currDistance) ? prev : curr;
        });
    }

    private createMarkerPopup(coordinate): void {
        let textBtn;
        this.translate.get('LEAFLET.synchronize').subscribe((res: any) => textBtn = res);
        this.map.closePopup();
        const latlng = new L.LatLng(coordinate.latitude, coordinate.longitude);
        const popup = L.popup({
            offset: new L.Point(0, -16)
        }).setLatLng(latlng).setContent(`
            <table class="table table-sm table-striped mb-0">
                <tbody>
                    <tr>
                        <td>Pos</td>
                        <td>${Moment(coordinate.coo_time).format('DD/MM/YYYY HH:mm:ss')}</td>
                    </tr>
                    <tr>
                        <td>Lat</td>
                        <td>${coordinate.latitude}</td>
                    </tr>
                    <tr>
                        <td>Lon</td>
                        <td>${coordinate.longitude}</td>
                    </tr>
                    <tr>
                        <td>Alt</td>
                        <td>${coordinate.elevation}m</td>
                    </tr>
                </tbody>
            </table>
            <button class="btn btn-sm btn-block btn-primary markerPopupBtn" data-type="${coordinate.coo_time}">${textBtn}</button>
        `).openOn(this.map);
    }

}
