import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageComponent } from './page.component';
import { SharedModule } from './shared/shared.module';
import { AppTokenInterceptor, UatInterceptor } from './helpers';
import { AutologComponent } from './autolog/autolog.component';
import { AlertComponent } from './alert/alert.component';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppleSigninModule } from 'ngx-apple-signin';
import { CallbackComponent } from './callback/callback.component';
@NgModule({
    declarations: [
        AppComponent,
        PageComponent,
        AutologComponent,
        AlertComponent,
        CallbackComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot(),
        HttpClientModule,
        TranslateModule.forRoot({
            defaultLanguage: 'en-EN',
            loader: {
                provide: TranslateLoader,
                useFactory: httpTranslateLoader,
                deps: [HttpClient]
            }
        }),
        AppRoutingModule,
        NgbModule,
        SharedModule,
        AppleSigninModule
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AppTokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: UatInterceptor, multi: true },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/');
}
