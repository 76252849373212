<div class="navigation">

    <div class="profile" *ngIf="currentUser">
        <img [src]="currentUser.avatar" height="40" placement="right" [ngbTooltip]="currentUser.username" tooltipClass="tooltip">
    </div>

    <ul class="nav-top">
        <li *ngFor="let nav of navsTop" [routerLink]="nav.route" routerLinkActive="active" placement="right" [ngbTooltip]="nav.name | translate">
            <i class="icon" [ngClass]="nav.icon"></i>
            <span class="name">{{ nav.name | translate }}</span>
        </li>
    </ul>

    <ul class="nav-bottom">
        <li *ngFor="let nav of navsBottom" [routerLink]="nav.route" routerLinkActive="active" placement="right" [ngbTooltip]="nav.name | translate">
            <i class="icon" [ngClass]="nav.icon"></i>
            <span class="name">{{ nav.name | translate }}</span>
        </li>
    </ul>

</div>