import { Component, ViewChild, ElementRef, ViewEncapsulation, Input, AfterViewInit, OnInit } from '@angular/core';
import { EventService } from 'src/app/services';

@Component({
    selector: 'app-video-player',
    templateUrl: './video-player.component.html',
    styleUrls: ['./video-player.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class VideoPlayerComponent implements OnInit, AfterViewInit {

    @Input() source: string;
    @Input() duration = 0;
    @Input() timeline = true;
    @Input() controls = true;
    @Input() currentTime = 0;

    @ViewChild('videoPlayer') videoplayer: ElementRef;

    playBtn = false;
    mutedBtn: boolean;
    unitBySpeed: boolean;
    progressBarValue = 0;
    currentSpeed = 1;
    activatedSync: boolean;

    constructor(private eventService: EventService) { }

    ngOnInit(): void {
        this.mutedBtn = this.eventService.getVolume();
        this.unitBySpeed = this.eventService.getChartUnit();
        this.eventService.time.subscribe((time) => {
            if (time.updateOnClick) {
                this.videoplayer.nativeElement.currentTime = time.current;
                this.currentTime = time.current;
            }
        });
    }

    ngAfterViewInit(): void {
        if (this.currentTime) {
            this.videoplayer.nativeElement.currentTime = this.currentTime;
        }
        this.videoplayer.nativeElement.volume = (this.eventService.getVolume()) ? 0 : 1;
    }

    toggleVideo(): void {
        if (!this.controls) { return; }
        if (this.playBtn) {
            this.videoplayer.nativeElement.pause();
            this.playBtn = !this.playBtn;
        } else {
            this.videoplayer.nativeElement.play();
            this.playBtn = !this.playBtn;
        }
    }

    changeSpeed(): void {
        switch (this.currentSpeed) {
            case 1:
                this.currentSpeed = 2;
                break;
            case 2:
                this.currentSpeed = 4;
                break;
            case 4:
                this.currentSpeed = 1;
                break;
        }
        this.videoplayer.nativeElement.playbackRate = this.currentSpeed;
    }

    toggleMuted(): void {
        this.mutedBtn = !this.mutedBtn;
        this.eventService.setVolume(this.mutedBtn);
        this.videoplayer.nativeElement.volume = (this.mutedBtn) ? 0 : 1;
        if (localStorage.getItem('ee') === 'ee') {
            localStorage.setItem('ee', 'ff');
        }
    }

    toggleFullscreen(): void {
        this.videoplayer.nativeElement.requestFullscreen();
    }

    timeUpdate(event: any): void {
        if (this.playBtn) {
            if (!this.duration) {
                this.duration = event.srcElement.duration;
                this.eventService.setDuration(this.duration);
            }
            this.eventService.setTime(event.srcElement.currentTime);
            this.currentTime = event.srcElement.currentTime;
            if (this.timeline) {
                this.progressBarValue = 100 * this.currentTime / this.duration;
            }
        }
    }

    changeTime(event: any): void {
        if (this.duration) {
            const progressBarWidth = event.path[1].offsetWidth;
            // console.log('progressBarWidth:', progressBarWidth);
            const cursorPos = event.offsetX;
            // console.log('cursorPos:', cursorPos);
            const pourcentage = (100 * cursorPos) / progressBarWidth;
            // console.log('pourcentage & progressBarValue:', pourcentage);
            this.progressBarValue = pourcentage;
            this.videoplayer.nativeElement.currentTime = (pourcentage / 100) * this.duration;
            // console.log('sec in video:', (pourcentage / 100) * this.duration);
        }
    }

    changeUnit(): void {
        this.unitBySpeed = !this.unitBySpeed;
        this.eventService.updateChartUnit(this.unitBySpeed);
    }

    activateSync(): void {
        this.activatedSync = !this.activatedSync;
        this.eventService.videoSync.emit(this.activatedSync);
    }

}
