export const API_URL = 'https://api.kinomap.dev/v3';
export const API_URL_V4 = 'https://api.kinomap.dev/v4';
export const APP_TOKEN =
  'NcREBvqyI55j3py9oEN58bocnxsy5AsHC9b1vro3kjacyxbK1OF0PT00fip8GFF7EEYXWcO4lP6Hc7i1JLRntHdyOquiV0fou49e4O7et1LtZRkwt0JKvL89';
export const APP_TOKEN_CONVERT =
  '4Og7wlN6T8JLGRFwcrIalyfYFbuy4y7XoPixoB4sw45MWzul4hsQtpIXrqR0faZ8SOO1UQTmkXTGk6zA7QHgGzESrwjM9WkOOL8XYQpHevifj7lobALwRPGf';
export const CONVERTER = 'https://converter.kinomap.com';

export const environment = {
  api: {
    auth: API_URL + '/auth/signIn',
    gps: API_URL + '/gpsOnly/',
    me: API_URL_V4 + '/me',
    swarm: {
      apple: API_URL + '/auth/loginApple',
      decathlon: API_URL + '/auth/loginDecathlon',
      google: API_URL + '/auth/loginGoogle',
      strava: API_URL + '/auth/loginStrava',
    },
    tags: API_URL + '/tags',
    thumbnail: API_URL_V4 + '/converters/videoThumbnailTime/',
    users: API_URL + '/users',
    videos: API_URL + '/videos/',
    votw: API_URL + '/votw',
    workout: API_URL_V4 + '/workout',
  },
  apple: {
    redirect_url: 'https://creator.kinomap.com/callback/apple',
  },
  decathlon: {
    config: {
      client_id: '0cd4eea3-363d-4606-9faa-a93fcac6d946',
      client_secret:
        '0cTZNom4AZ1dHaIFHA3gksJsIvkegI8vQ4wNEtKHY0MqgLtEzanNCTy42Im1HcGJfvgIMNZ8BCFTUTxtyC0R7pcfv1Upw6yAUpLQs05uHRAMnrBHqRZtogncdlrhedQC',
    },
    dataUrl: 'https://api-global.decathlon.net/connect/oauth/token',
    loginUrl: 'https://api-global.decathlon.net/connect/oauth/authorize',
    redirectUrl: 'https://creator.kinomap.com/callback/decathlon',
  },
  google: {
    client_id:
      '466170937286-7ko89334cm4cvrt4pa1728cdmp5he3qi.apps.googleusercontent.com',
  },
  production: true,
  strava: {
    client_id: 22520,
    client_secret: '97c8b931e57a177f1d8072c719545d82fa6050d8',
    loginUrl: 'https://www.strava.com/oauth/authorize',
    redirectUrl: 'https://creator.kinomap.com/callback/strava',
    tokenUrl: 'https://www.strava.com/oauth/token',
  },
};
